/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { ClassGroups } from "../components";
import Content, { HTMLContent } from "../components/Content";
import Layout from "../components/Layout";

export const ClassGroupsPageTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section columns">
      <div className="column is-10 is-offset-1">
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          {title}
        </h2>
        <PageContent className="content" content={content} />
        <ClassGroups />
        <br />
        <div className="mb-4">
          <a href="https://forms.gle/3oy7agNQ6oQqzXqN6" target="_blank">
            <div className="notification is-dark">
              <strong>We're Recruiting - Tech Team!</strong> <br />
              Join VITspot Team
            </div>
          </a>
        </div>
        <span className="has-text-dark has-text-centered column">
          Developed by{" "}
          <a
            href="https://github.com/Deepakmukka1"
            className="is-size-6 has-text-weight-semibold"
            target="_blank"
          >
            Mukka Deepak
          </a>
          &nbsp; at VITspot
        </span>
      </div>
    </section>
  );
};

ClassGroupsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ClassGroupsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ClassGroupsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

ClassGroupsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClassGroupsPage;

export const pageQuery = graphql`
  query ClassGroupsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
